@minor-breakpoint: 40rem; // 640px
@major-breakpoint: 60rem; // 960px
@content-max-width: 90rem; // 1440px

@rem-size: 16;

// halfs, thirds, quarters + custom
@grids: 2, 3, 4, 5, 6, 12, 16;


.container {
    max-width: @content-max-width;
    margin: auto;
}

.grid {
    display: block;
    width: 100%;
    font-size: 0 !important;
    vertical-align: middle;
    > * {
        display: inline-block;
        width: 100%;
        font-size: 1rem;
        vertical-align: inherit;
    }
}

// tablet and desktop
@media (min-width: @minor-breakpoint) {
    .generate-grids(@grids);
    .generate-class(hidden, display, ~'none !important');
}

// mobile and tablet
@media (max-width: (@major-breakpoint - 1 / @rem-size)) {
    .generate-grids(@grids, -md-sm);
    .generate-class(hidden, display, ~'none !important', -md-sm);
}

// mobile
@media (max-width: (@minor-breakpoint - 1 / @rem-size)) {
    .generate-grids(@grids, -sm);
    .generate-class(hidden, display, ~'none !important', -sm);
}

// tablet
@media (min-width: @minor-breakpoint) and (max-width: (@major-breakpoint - 1 / @rem-size)) {
    .generate-grids(@grids, -md);
    .generate-class(hidden, display, ~'none !important', -md);
}

// desktop
@media (min-width: @major-breakpoint) {
    .generate-grids(@grids, -lg);
    .generate-class(hidden, display, ~'none !important', -lg);
}


// formulae
.generate-columns(@width, @screen-size: ~'', @i: 1) when (@i <= @width) {
    @size: percentage((@i / @width));

    .col-@{i}-@{width}@{screen-size} {
        width: @size;
    }

    .off-@{i}-@{width}@{screen-size} {
        margin-left: @size;
    }

    .generate-columns(@width, @screen-size, (@i + 1));
};

.generate-grids(@grids, @screen-size: ~'', @i: 1) when (@i <= length(@grids)) {
    @width: extract(@grids, @i);

    .generate-columns(@width, @screen-size);

    .generate-grids(@grids, @screen-size, (@i + 1));
};


.generate-spacing(@spaces, @unit: ~'', @breakpoint: ~'', @i: 1) when (@i <= length(@spaces)) {
    @space: extract(@spaces, @i);

    // MARGINS
    .margin-@{space}@{breakpoint} {
        margin: ~'@{space}@{unit}';
    }

    .margin-horizontal-@{space}@{breakpoint} {
        margin-left: ~'@{space}@{unit}';
        margin-right: ~'@{space}@{unit}';
    }

    .margin-vertical-@{space}@{breakpoint} {
        margin-top: ~'@{space}@{unit}';
        margin-bottom: ~'@{space}@{unit}';
    }

    .margin-top-@{space}@{breakpoint} {
        margin-top: ~'@{space}@{unit}';
    }

    .margin-right-@{space}@{breakpoint} {
        margin-right: ~'@{space}@{unit}';
    }

    .margin-bottom-@{space}@{breakpoint} {
        margin-bottom: ~'@{space}@{unit}';
    }

    .margin-left-@{space}@{breakpoint} {
        margin-left: ~'@{space}@{unit}';
    }

    // PADDINGS
    .padding-@{space}@{breakpoint} {
        padding: ~'@{space}@{unit}';
    }

    .padding-horizontal-@{space}@{breakpoint} {
        padding-left: ~'@{space}@{unit}';
        padding-right: ~'@{space}@{unit}';
    }

    .padding-vertical-@{space}@{breakpoint} {
        padding-top: ~'@{space}@{unit}';
        padding-bottom: ~'@{space}@{unit}';
    }

    .padding-top-@{space}@{breakpoint} {
        padding-top: ~'@{space}@{unit}';
    }

    .padding-right-@{space}@{breakpoint} {
        padding-right: ~'@{space}@{unit}';
    }

    .padding-bottom-@{space}@{breakpoint} {
        padding-bottom: ~'@{space}@{unit}';
    }

    .padding-left-@{space}@{breakpoint} {
        padding-left: ~'@{space}@{unit}';
    }

    // REPEAT until @i = 0
    .generate-spacing(@spaces, @unit, @breakpoint, (@i + 1));
}

.generate-class(@name, @property, @value, @screen-size: ~'') {
    .@{name}@{screen-size} {
        @{property}: @value;
    }
}